import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Divider, Paper, Button } from '@mui/material';
import Sidebar from '../../components/global/Sidebar';
import Topbar from '../../components/global/Topbar';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill stylesheet
import { useAuthContext } from '../../context/AuthContext';
import ClipLoader from 'react-spinners/ClipLoader';

function getHeaders(headers = {}) {
    const accessToken = localStorage.getItem('access_token');
    let newHeaders = { ...headers, 'Content-Type': 'application/json' };
    if (accessToken) {
        newHeaders = {
            ...newHeaders,
            Authorization: `Bearer ${accessToken}`
        };
    }
    return newHeaders;
}

const AgentTraining = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const [data, setData] = useState(null);
    const [data1, setData1] = useState(null);

    const [expandedSubheadings, setExpandedSubheadings] = useState({}); // Stores content for multiple expanded subheadings
    const [loadingSubheadings, setLoadingSubheadings] = useState({}); // Tracks which subheading is loading
    const url = `${process.env.REACT_APP_API_URL}/training/table-of-contents`;
    const urlContent = `${process.env.REACT_APP_API_URL}/training/contents/by-heading`;
    const urlContentUpdate = `${process.env.REACT_APP_API_URL}/training/contents`;
    const {
        user: { role: userRole }
    } = useAuthContext();
    // Fetch table of contents data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: getHeaders()
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log(id);
                const result = await response.json();
                const filteredData = result.list.filter(
                    (item) => item.level === 2 && item.parent_id === id
                );

                setData1(result.list);

                console.log(filteredData);
                setData(filteredData); // Store only the filtered data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Handle subheading click and fetch its content
    const handleSubheadingClick = async (subheadingId) => {
        if (expandedSubheadings[subheadingId]) {
            // Collapse the subheading if already expanded
            setExpandedSubheadings((prev) => {
                const newExpanded = { ...prev };
                delete newExpanded[subheadingId];
                return newExpanded;
            });
        } else {
            // Fetch the content if not already expanded
            setLoadingSubheadings((prev) => ({ ...prev, [subheadingId]: true }));
            try {
                const response = await fetch(`${urlContent}/${subheadingId}`, {
                    method: 'GET',
                    headers: getHeaders()
                });

                if (!response.ok) {
                    throw new Error('Error fetching subheading content');
                }

                const result = await response.json();

                setExpandedSubheadings((prev) => ({
                    ...prev,
                    [subheadingId]: result.text
                }));
            } catch (error) {
                console.error('Error fetching subheading content:', error);
            } finally {
                setLoadingSubheadings((prev) => ({
                    ...prev,
                    [subheadingId]: false
                }));
            }
        }
    };

    // Render the content hierarchy
    const renderContent = () => {
        if (!data) return null;

        const level1Items = data.filter((item) => item.level === 2);

        return level1Items.map((level1Item) => {
            const level2Items = data1.filter((item) => item.parent_id === level1Item.id);
            console.log(level2Items);

            return (
                <Paper
                    key={level1Item.id}
                    elevation={2}
                    sx={{
                        padding: '20px',
                        marginBottom: '30px',
                        borderRadius: '8px',
                        boxShadow: `0 3px 6px ${colors.grey[300]}`
                        // backgroundColor: colors.background.paper
                    }}
                >
                    {' '}
                    {/* Render level 1 headings */}
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            color: colors.primary[600],
                            paddingBottom: '10px',
                            borderBottom: `2px solid ${colors.primary[300]}`,
                            marginBottom: '20px'
                        }}
                    >
                        {level1Item.title}
                    </Typography>
                    {/* Render level 2 subheadings */}
                    <Box sx={{ marginLeft: '20px' }}>
                        {level2Items.map((level2Item) => (
                            <Box key={level2Item.id} sx={{ marginBottom: '15px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: colors.grey[700],
                                            cursor: 'pointer',
                                            fontWeight: '500',
                                            '&:hover': {
                                                color: colors.primary[500]
                                            }
                                        }}
                                        onClick={() => handleSubheadingClick(level2Item.id)}
                                    >
                                        {level2Item.title}
                                    </Typography>
                                    {userRole === 'admin' && (
                                        <Button
                                            variant="contained"
                                            onClick={() => handleEditClick(level2Item.id)}
                                            sx={{
                                                color: 'white',
                                                background: colors.primary[500],
                                                fontSize: '12px',
                                                transition: 'background-color 0.3s',
                                                borderRadius: '90px', // Add border radius here
                                                '&:hover': {
                                                    background: '#5A4532' // Dark brown color on hover
                                                }
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </div>

                                {/* Show loading spinner if the content is being fetched */}
                                {loadingSubheadings[level2Item.id] && (
                                    <Box sx={{ paddingLeft: '10px', marginTop: '5px' }}>
                                        <CircularProgress size={20} />
                                    </Box>
                                )}

                                {/* Render the fetched content for expanded subheadings */}
                                {expandedSubheadings[level2Item.id] && (
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                            paddingLeft: '20px',
                                            borderLeft: `2px solid ${colors.primary[300]}`,
                                            '& ul': {
                                                // Add this to ensure proper list styling
                                                listStyleType: 'disc', // You can use 'disc', 'circle', etc.
                                                paddingLeft: '20px'
                                            },
                                            '& li': {
                                                marginBottom: '10px' // Optional for better spacing between list items
                                            }
                                        }}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: expandedSubheadings[level2Item.id]
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                </Paper>
            );
        });
    };

    const [selectedContent, setSelectedContent] = useState(null); // Store content data when editing
    const [isModalOpen, setIsModalOpen] = useState(false); // Track modal open/close state

    const handleEditClick = async (headingId) => {
        try {
            const response = await fetch(`${urlContent}/${headingId}`, {
                method: 'GET',
                headers: getHeaders()
            });

            if (!response.ok) {
                throw new Error('Error fetching content for editing');
            }

            const result = await response.json();
            console.log(result);
            setSelectedContent(result); // Store the content data
            setIsModalOpen(true); // Open the modal
        } catch (error) {
            console.error('Error fetching content:', error);
        }
    };

    const EditModal = ({ open, onClose, content, onSave }) => {
        const [editorContent, setEditorContent] = useState(content?.text || ''); // Initialize with content text

        useEffect(() => {
            if (content) {
                setEditorContent(content.text); // Set editor content when modal opens
            }
        }, [content]);

        const handleSave = () => {
            // Call the onSave function passed from parent with updated content
            onSave(editorContent);
            onClose(); // Close the modal after saving
        };

        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
                <DialogTitle>Edit Training Content</DialogTitle>
                <DialogContent>
                    {/* <Typography variant="body1">
                        <strong>Content ID:</strong> {content?.id}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Heading ID:</strong> {content?.training_heading_id}
                    </Typography> */}

                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={setEditorContent}
                        style={{ marginTop: '20px', height: '400px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleSaveContent = async (updatedContent) => {
        try {
            const response = await fetch(`${urlContentUpdate}/${selectedContent.id}`, {
                method: 'PUT',
                headers: getHeaders(),
                body: JSON.stringify({
                    text: updatedContent, // Updated HTML content from the editor
                    training_heading_id: selectedContent.training_heading_id // The heading ID
                })
            });

            if (!response.ok) {
                throw new Error('Failed to save content');
            }

            const result = await response.json();
            console.log('Content saved successfully:', result);

            // Optionally, update your state with the new content
            setExpandedSubheadings((prev) => ({
                ...prev,
                [selectedContent.training_heading_id]: updatedContent
            }));
        } catch (error) {
            console.error('Error saving content:', error);
        }
    };

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <Box
                    sx={{
                        marginLeft: { xs: '20px', sm: '40px', md: '80px', lg: '80px' },
                        marginRight: { xs: '20px', sm: '40px', md: '80px', lg: '80px' },
                        marginBottom: { xs: '20px', sm: '40px', md: '80px', lg: '80px' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '30px'
                        }}
                    >
                        <Typography
                            variant="h3"
                            sx={{ fontWeight: 'bold', color: colors.primary[700] }}
                        >
                            Training
                        </Typography>
                    </Box>

                    {/* Divider between header and content */}
                    <Divider sx={{ marginY: '20px', backgroundColor: colors.grey[300] }} />

                    {/* Render headings and subheadings */}
                    <Box sx={{ marginTop: '20px' }}>
                        {loading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '70vh' // Full viewport height
                                }}
                            >
                                <ClipLoader color="#a68e74" loading={loading} size={50} />
                            </div>
                        ) : (
                            <div>{renderContent()}</div>
                        )}
                    </Box>
                </Box>
                <EditModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    content={selectedContent}
                    onSave={handleSaveContent}
                />
            </main>
        </>
    );
};

export default AgentTraining;
