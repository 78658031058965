import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tokens } from '../../theme';
import { Checkbox, useTheme } from '@mui/material';
import Sidebar from '../global/Sidebar';
import { useLocation } from 'react-router-dom';
import Topbar from '../global/Topbar';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { getOrderById } from '../../api/orders';
import Appointment from '../list-leads/apointment';

import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'status', label: 'Status', minWidth: 150 },
    { id: 'description', label: 'Description', minWidth: 150 },
    { id: 'type', label: 'Type', minWidth: 150 },
    { id: 'appointment', label: 'Appointment', minWidth: 150 }
];

function createData(name, status, description, type, id) {
    return { name, status, description, type, id };
}

function OrderItem() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [leadReqId, setLeadReqId] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const [rowData, setRowData] = useState(null);

    useEffect(() => {
        const dataFromLocation = location.state && location.state.rowData;
        if (dataFromLocation) {
            setRowData(dataFromLocation);
        }
    }, [location.state]);

    useEffect(() => {
        console.log('data from orders page:', rowData);
        if (rowData && rowData.id) {
            setLeadReqId(rowData.id);
        }
    }, [rowData]);

    const {
        data: OrderData,
        error,
        isLoading
    } = useQuery(['getOrderById', rowData?.id], () => getOrderById(rowData?.id));

    console.log('data', OrderData);

    const rowsOrder = isLoading
        ? [] // Initialize it as an empty array when loading
        : OrderData?.data?.leads?.map((item) =>
              createData(item?.name, item?.status, item?.description, item?.type.name, item?.id)
          );

    console.log('leads', rowsOrder);

    //..................................................//
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleGoBack = () => {
        navigate(-1); // Goes back one page in history
    };

    //------------------------------setting appointment----------------------//
    const [isAppointmentOpen, setIsAppointmentOpen] = useState(false);
    const [selectedRowData1, setSelectedRowData1] = useState(null);

    const handleAppintmentClick = (row) => {
        // Set the selectedRow state with the row data
        setSelectedRowData1(row);
        setIsAppointmentOpen(true);
        console.log('Appointment dialog should be open now', row);
    };

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <Button
                    variant="contained"
                    sx={{
                        width: 100,
                        color: 'white',
                        background: colors.primary[500],
                        fontSize: '15px',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            background: '#5A4532' // Dark brown color on hover
                        },
                        marginLeft: 'auto', // Align the button to the right
                        marginLeft: '80px'
                    }}
                    onClick={handleGoBack}
                >
                    Back
                </Button>
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>Orders </h1>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '10px',
                                        marginTop: '25px'
                                    }}
                                ></div>
                            </Box>
                        </Box>

                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 600 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        border: '1px solid rgba(0, 0, 0, 0.1)',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsOrder
                                            ?.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code}
                                                        sx={{
                                                            '&:nth-of-type(even)': {
                                                                backgroundColor:
                                                                    'rgba(0, 0, 0, 0.03)'
                                                            }
                                                        }}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                >
                                                                    {column.id === 'appointment' ? (
                                                                        <Button
                                                                            aria-label="edit"
                                                                            type="submit"
                                                                            sx={{
                                                                                color: 'white',
                                                                                background:
                                                                                    '#a68e74',
                                                                                fontSize: '15px',
                                                                                width: '90px',
                                                                                transition:
                                                                                    'background-color 0.3s',
                                                                                '&:hover': {
                                                                                    background:
                                                                                        '#5A4532'
                                                                                }
                                                                            }}
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleAppintmentClick(
                                                                                    row
                                                                                )
                                                                            }
                                                                        >
                                                                            Set
                                                                        </Button>
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rowsOrder?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <Appointment
                            open={isAppointmentOpen}
                            rowData={selectedRowData1}
                            onClose={() => setIsAppointmentOpen(false)}
                            // updateTableData={updateTableData} // Pass update function as prop
                        />
                    </Box>
                </div>
            </main>
        </>
    );
}

export default OrderItem;
