import React from 'react';
import TableCurrentLeads from '../../components/curren-leads/tableCurrentLeads';
import { Box } from '@mui/material';
import Sidebar from '../../components/global/Sidebar';
import Topbar from '../../components/global/Topbar';
import CircularIndeterminate from '../../components/circularProgress';
import { useQuery } from 'react-query';
import { CurrentLeadsGet } from '../../api/current-leads';
import { useState, useEffect } from 'react';

const columns = [
    { id: 'type', label: 'Lead Type', minWidth: 150 },
    { id: 'zip_code', label: 'Zip Code', minWidth: 150 },
    {
        id: 'name',
        label: 'Lead Name',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },

    {
        id: 'description',
        label: 'Description',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },

    {
        id: 'actions',
        label: 'Actions',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    }
];

function createData(type, zip_code, name, description, id, agent_id) {
    return { type, zip_code, name, description, id, agent_id };
}

const CurrentLeads = () => {
    const {
        data: currentLeadsData,
        error,
        isLoading
    } = useQuery('CurrentLeadsGet', CurrentLeadsGet);
    const [rows, setRows] = useState([]);
    console.log(currentLeadsData?.data?.list);

    useEffect(() => {
        if (!isLoading && currentLeadsData?.data) {
            const myrows = currentLeadsData?.data?.list?.map((item, index) =>
                createData(
                    item?.type?.name,
                    item?.zip_code,
                    item?.name,

                    item?.description,
                    item?.id,
                    item?.agent_id
                )
            );
            setRows(myrows);
        }
    }, [isLoading, currentLeadsData]);
    console.log(rows);
    const fetchUpdatedData = async () => {
        try {
            const response = await CurrentLeadsGet(); // Call your API or method to fetch updated data
            const updatedData = response?.data?.list?.map((item, index) =>
                createData(
                    item?.type?.name,
                    item?.zip_code,
                    item?.name,
                    item?.agent_id,
                    item?.description,
                    item?.id
                )
            );
            setRows(updatedData); // Update the state with the new data fetched
            console.log('updated', updatedData);
        } catch (error) {
            console.error('Error fetching updated data:', error);
        }
    };

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />

                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>Current Leads</h1>
                        </Box>
                        {isLoading ? (
                            <CircularIndeterminate />
                        ) : error ? (
                            <div>Error: {error.message}</div>
                        ) : (
                            <TableCurrentLeads
                                rows={rows}
                                columns={columns}
                                fetchUpdatedData={fetchUpdatedData}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '20px'
                            }}
                        ></Box>
                    </Box>
                </div>
            </main>
        </>
    );
};

export default CurrentLeads;
