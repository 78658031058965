import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Card, CardContent, Button, Grid } from '@mui/material';
import Sidebar from '../../components/global/Sidebar';
import Topbar from '../../components/global/Topbar';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ClipLoader from 'react-spinners/ClipLoader';

function getHeaders(headers = {}) {
    const accessToken = localStorage.getItem('access_token');
    let newHeaders = { ...headers, 'Content-Type': 'application/json' };
    if (accessToken) {
        newHeaders = {
            ...newHeaders,
            Authorization: `Bearer ${accessToken}`
        };
    }
    return newHeaders;
}

const Training = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(true);

    // State to hold the training data
    const [trainingData, setTrainingData] = useState([]);
    const url = `${process.env.REACT_APP_API_URL}/training/table-of-contents`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: getHeaders()
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json(); // Parse the JSON response
                const filteredData = data.list.filter((item) => item.level === 1); // Filter level 1 items
                setTrainingData(filteredData); // Store only the filtered data
                console.log(trainingData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Icons array for illustrative purposes (you can map these dynamically if needed)
    const icons = [
        <VerifiedUserIcon sx={{ fontSize: 50, color: colors.primary[600] }} />,
        <SchoolIcon sx={{ fontSize: 50, color: colors.primary[600] }} />,
        <WorkIcon sx={{ fontSize: 50, color: colors.primary[600] }} />
    ];

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <Box
                    sx={{
                        marginLeft: { xs: '20px', sm: '40px', md: '80px', lg: '80px' },
                        marginRight: { xs: '20px', sm: '40px', md: '80px', lg: '80px' },
                        marginBottom: { xs: '20px', sm: '40px', md: '80px', lg: '80px' },
                        marginTop: '30px'
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{ fontWeight: 'bold', color: colors.primary[700], textAlign: 'center' }}
                    >
                        Training
                    </Typography>

                    {/* Divider between header and content */}
                    <Divider sx={{ marginY: '20px', backgroundColor: colors.grey[300] }} />

                    <Grid container spacing={3}>
                        {loading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '150vh',
                                    height: '70vh' // Full viewport height
                                }}
                            >
                                <ClipLoader color="#a68e74" loading={loading} size={50} />
                            </div>
                        ) : (
                            <>
                                {trainingData.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={card.id}>
                                        <Card
                                            sx={{
                                                minHeight: '200px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                padding: '20px',
                                                backgroundColor: colors.grey[100],
                                                border: `1px solid ${colors.grey[300]}`
                                            }}
                                        >
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginBottom: '20px'
                                                    }}
                                                >
                                                    {icons[index % icons.length]}{' '}
                                                    {/* Cycling through icons */}
                                                </Box>
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        marginBottom: '10px'
                                                    }}
                                                >
                                                    {card.title}
                                                </Typography>
                                            </CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: '#a68e74' }}
                                                    component={Link}
                                                    to={`/training/2/${card.id}`}
                                                >
                                                    Learn More
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                </Box>
            </main>
        </>
    );
};

export default Training;
