import notify from './notify';

function getHeaders(headers = {}) {
    const accessToken = localStorage.getItem('access_token');
    let newHeaders = { ...headers, 'Content-Type': 'application/json' };
    if (accessToken) {
        newHeaders = {
            ...newHeaders,
            Authorization: `Bearer ${accessToken}`
        };
    }

    return newHeaders;
}

async function makeRequest(method, endpoint, body = {}, headers = {}) {
    const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
    console.log('inside makerequest in api.js', endpoint);

    const newHeaders = getHeaders(headers);

    let response = null;
    if (method === 'GET') {
        response = await fetch(url, {
            method,
            headers: {
                ...newHeaders
            }
        });
    } else {
        response = await fetch(url, {
            method,
            headers: {
                ...newHeaders
            },
            body: JSON.stringify(body)
        });
    }

    return response;
}

export async function postHelper(endpoint, body, headers = {}) {
    try {
        const response = await makeRequest('POST', endpoint, body, headers);
        return handleApiResponse(response);
    } catch (e) {
        return { status: false, message: e.message ?? 'Something went wrong' };
    }
}

export async function getHelper(endpoint, headers = {}) {
    try {
        const response = await makeRequest('GET', endpoint, {}, headers);
        return handleApiResponse(response);
    } catch (e) {
        return { status: false, message: e.message ?? 'Something went wrong' };
    }
}

export async function putHelper(endpoint, body, headers = {}) {
    try {
        const response = await makeRequest('PUT', endpoint, body, headers);

        return handleApiResponse(response);
    } catch (e) {
        return { status: false, message: e.message ?? 'Something went wrong' };
    }
}

export async function deleteHelper(endpoint, body, headers = {}) {
    try {
        const response = await makeRequest('DELETE', endpoint, body, headers);
        return handleApiResponse(response);
    } catch (e) {
        return { status: false, message: e.message ?? 'Something went wrong' };
    }
}

async function handleApiResponse(response) {
    const data = await response.json();

    if (response.status === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        return window.location.reload();
    }

    if (response.status >= 200 && response.status < 400) {
        return { status: true, message: 'success', data };
    } else {
        const message = data?.error ?? 'Something went wrong';
        // notify('error', message);
        return { status: false, message };
    }
}
