import { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import logo from '../../data/pics/logo.png';
import HistoryIcon from '@mui/icons-material/History';
// export default Sidebar;
import React from 'react';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useLocation } from 'react-router-dom';

import 'react-pro-sidebar/dist/css/styles.css';
import { useAuthContext } from '../../context/AuthContext';
// ... (other icon imports)

const Item = ({ title, to, icon, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation(); // Get current location
    const isActive = location.pathname === to;

    return (
        <MenuItem
            active={isActive}
            style={{
                color: isActive ? 'white' : colors.grey[100], // Change text color
                backgroundColor: isActive ? colors.primary[500] : 'transparent',
                borderRadius: isActive ? '19px' : '0',
                minWidth: '280px',
                boxShadow: isActive ? `0 0 4px ${colors.primary[500]}` : 'none',
                margin: '5px'
            }}
            onClick={() => setSelected(title)}
            icon={React.cloneElement(icon, {
                style: {
                    ...icon.props.style,
                    color: isActive ? 'white' : undefined // Change icon color
                }
            })}
        >
            <Typography sx={{ fontSize: '15px', color: isActive ? 'white' : 'black' }}>
                {title}
            </Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const {
        user: { role: userRole }
    } = useAuthContext();

    return (
        <Box
            sx={{
                '& .pro-sidebar-inner': {
                    background: 'white !important'
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important'
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important'
                },
                '& .pro-inner-item:hover': {
                    color: '#a68e74 !important'
                },
                '& .pro-menu-item.active': {
                    color: '#a68e74 !important'
                }
            }}
        >
            <ProSidebar collapsed={isCollapsed} width={320}>
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100]
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box mb="25px">
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <img
                                alt="profile-user"
                                width="150px"
                                height={isCollapsed ? '0' : '100px'}
                                src={logo}
                                style={{ cursor: 'pointer', transition: 'height 0.3s' }}
                            />
                        </Box>
                    </Box>

                    {/* Show menu items */}
                    {!isCollapsed && (
                        <>
                            {' '}
                            <Box paddingLeft="10%">
                                <Item
                                    title="Home"
                                    to="/"
                                    icon={<HomeOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {userRole === 'admin' && (
                                    <>
                                        <Item
                                            title="Manage Agent"
                                            to="/manage-agents"
                                            icon={<HeadsetMicIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Manage Leads Vendor"
                                            to="/manage-lead-vendors"
                                            icon={<ManageAccountsIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Calling Center Vendors"
                                            to="/manage-calling-center-vendors"
                                            icon={<SupportAgentIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Training"
                                            to="/training"
                                            icon={<SupportAgentIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        {/* <Item
                                            title="Approval / Disapproval"
                                            to="/approvalDisapproval"
                                            icon={<HelpOutlineOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> */}
                                        {/* <Item
                      title="Current Orders"
                      to="/currentOrders"
                      icon={<HeadsetMicIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="Current Leads"
                      to="/currentLeads"
                      icon={<PsychologyIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="List Agents"
                      to="/listAgents"
                      icon={<HeadsetMicIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title="List Leads"
                      to="/listLeads"
                      icon={<PsychologyIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    /> */}
                                    </>
                                )}
                                {userRole === 'vendor' && (
                                    <>
                                        <Item
                                            title="Current Orders"
                                            to="/current-orders"
                                            icon={<HeadsetMicIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Current Leads"
                                            to="/current-leads"
                                            icon={<PsychologyIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Training"
                                            to="/training"
                                            icon={<SupportAgentIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        {/* <Item
                                            title="Previous Orders"
                                            to="/previous-orders"
                                            icon={<HistoryIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> */}
                                        {/* <Item
                                            title="Payment History"
                                            to="/paymentHistory"
                                            icon={<PaymentsIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> */}
                                    </>
                                )}
                                {userRole === 'agent' && (
                                    <>
                                        {/* <Item
                                            title="List Agents"
                                            to="/list-agents"
                                            icon={<HeadsetMicIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> */}
                                        {/* <Item
                                            title="List Leads"
                                            to="/list-leads"
                                            icon={<PsychologyIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        /> */}
                                    </>
                                )}
                                {userRole === 'calling_agent' && (
                                    <>
                                        {/* <Item title="List Agents" to="/listAgents" icon={<HeadsetMicIcon />} selected={selected} setSelected={setSelected} /> */}
                                        <Item
                                            title="List Leads"
                                            to="/list-leads"
                                            icon={<PsychologyIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Orders"
                                            to="/orders"
                                            icon={<HeadsetMicIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Training"
                                            to="/training"
                                            icon={<SupportAgentIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </>
                                )}
                            </Box>
                        </>
                    )}
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
