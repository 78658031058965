import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import CircularIndeterminate from './circularProgress';
import AttachFileIcon from '@mui/icons-material/AttachFile';
const CustomTable = ({
    columns,
    rows,
    totalRecords,
    loading,
    handlePageChange,
    handleSizePerPageChange,
    title = '',
    currentPage = 1,
    rowsPerPage = 20
}) => {
    return (
        <>
            <Typography variant="h4" fontWeight={600}>
                {title}
            </Typography>
            <Box sx={{ padding: '.6rem' }} />
            <Paper sx={{ width: '100%' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {(columns ?? [])?.map((column) => (
                                    <TableCell
                                        key={column}
                                        style={{
                                            border: '1px solid rgba(0, 0, 0, 0.1)',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {column}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ minHeight: '300px' }}>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns?.length ?? 1}>
                                        <CircularIndeterminate />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {totalRecords === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={columns?.length ?? 1}>
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        padding: '.6rem'
                                                    }}
                                                >
                                                    <div>
                                                        <AttachFileIcon fontSize="large" />
                                                    </div>
                                                    <div style={{ fontSize: '1.2em' }}>
                                                        No data found.
                                                    </div>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        rows
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 50]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={currentPage - 1}
                    onPageChange={(_, page) => handlePageChange?.(page)}
                    onRowsPerPageChange={(e) => handleSizePerPageChange?.(e.target.value)}
                />
            </Paper>
        </>
    );
};

export default CustomTable;
