import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox'; // Add this import
import Button from '@mui/material/Button';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import { Box } from '@mui/material';
import AssignCurrentLeads from './assignCurrentLeads';
import { useEffect } from 'react';

export default function TableCurrentLeads(props) {
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);
    const { rows, columns, fetchUpdatedData } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows

    const [selectedRowData, setSelectedRowData] = useState(null);

    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Function to handle checkbox selection for each row
    const handleCheckboxClick = (event, row) => {
        const isSelected = event.target.checked;
        if (isSelected) {
            // Add the row's data to the selectedRows array if not already added
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
        } else {
            // Remove the row's data from the selectedRows array
            setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id)
            );
        }
    };

    // Function to log selected data
    // Function to log selected data and clear the selectedRows state
    const logSelectedData = () => {
        setIsEditDialogOpen(true);
        setSelectedRowData(selectedRows);
        console.log('Selected rows  : ', selectedRows);
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '30px'
                }}
            >
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        height: 40,
                        width: 220,
                        color: 'white',
                        background: colors.primary[500],
                        fontSize: '15px',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            background: '#5A4532' // Dark brown color on hover
                        },
                        marginLeft: 'auto' // Align the button to the right
                    }}
                    onClick={logSelectedData}
                >
                    Assign
                </Button>{' '}
            </Box>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            border: '1px solid rgba(0, 0, 0, 0.1)',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.order} // Use the unique order as the key
                                            sx={{
                                                '&:nth-of-type(even)': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                                }
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'actions' ? (
                                                            <>
                                                                <div style={{ display: 'flex' }}>
                                                                    {row.agent_id === null && ( // Check for null or undefined
                                                                        <Checkbox
                                                                            checked={selectedRows.some(
                                                                                (selectedRow) =>
                                                                                    selectedRow.id ===
                                                                                    row.id
                                                                            )}
                                                                            onChange={(event) =>
                                                                                handleCheckboxClick(
                                                                                    event,
                                                                                    row
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : column.format &&
                                                          typeof value === 'number' ? (
                                                            column.format(value)
                                                        ) : (
                                                            value
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <AssignCurrentLeads
                open={isEditDialogOpen}
                rowData={selectedRowData}
                fetchUpdatedData={fetchUpdatedData}
                onClose={() => setIsEditDialogOpen(false)}
            />
        </div>
    );
}
