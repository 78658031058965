import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tokens } from '../../theme';
import { Checkbox, useTheme } from '@mui/material';
import Sidebar from '../global/Sidebar';
import { useLocation } from 'react-router-dom';
import Topbar from '../global/Topbar';
import { Box } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import AssignLeads from './assignLeads';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';
import { CheckBox } from '@mui/icons-material';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import { saveAs } from 'file-saver'; // You can use this or a similar library to handle file downloads

const columns = [
    { id: 'orderAlias', label: 'Order ID', minWidth: 150 },
    { id: 'leadType', label: 'Lead Type', minWidth: 150 },
    { id: 'zipCode', label: 'Zip Code', minWidth: 150 },
    { id: 'city', label: 'City', minWidth: 150 },
    { id: 'quantity', label: 'Quantity', minWidth: 150 },
    { id: 'assign', label: 'Assign', minWidth: 150 }

    // { id: 'assign', label: 'Assign', minWidth: 150 }
];

function createData(orderAlias, leadType, zipCode, city, quantity, state, country) {
    return { orderAlias, leadType, zipCode, city, quantity, state, country };
}

function TableOrders() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedCityFilter, setSelectedCityFilter] = useState('all'); // Initialize with 'all' or any default value
    const [leadReqId, setLeadReqId] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const [rowData, setRowData] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows
    const [uniqueCities, setUniqueCities] = useState([]); // State to store unique cities

    useEffect(() => {
        const dataFromLocation = location.state && location.state.rowData;
        if (dataFromLocation) {
            setRowData(dataFromLocation);
        }
    }, [location.state]);

    useEffect(() => {
        console.log('data from orders page:', rowData);
        if (rowData && rowData.id) {
            setLeadReqId(rowData.id);
        }
    }, [rowData]);

    const handleCityFilterChange = (event) => {
        setSelectedCityFilter(event.target.value);
    };

    const filteredRows = rowData?.lead_request_items?.filter((item) => {
        if (selectedCityFilter === 'all') {
            return true;
        } else {
            return item.city === selectedCityFilter;
        }
    });
    console.log('Filtered rows: ', filteredRows);
    const rows = filteredRows?.map((item, index) => {
        const alias = rowData?.alias;
        return createData(alias, item?.type?.name, item?.zip_code, item?.city, item?.quantity);
    }); //.................................................//

    useEffect(() => {
        if (rowData && rowData.lead_request_items) {
            const cities = new Set();
            rowData.lead_request_items.forEach((item) => {
                cities.add(item.city);
            });
            setUniqueCities(Array.from(cities));
        }
    }, [rowData]);

    const handleOrderAssign = (row) => {
        setSelectedRowData(row);
        console.log('Order data :', row);
    };
    //..................................................//
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [isAssignOpen, setIsAssignOpen] = useState(false);
    const [selectedRowData1, setSelectedRowData1] = useState(null);

    const logSelectedData = (row) => {
        setIsAssignOpen(true);
        setSelectedRowData1(row);
        console.log('Selected rows  : ', selectedRowData1);
    };

    //-------------------------------data import from excel-----------------------//

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPageCount, setRowsPerPageCount] = useState(5);
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            const accessToken = localStorage.getItem('access_token');
            const headers = {
                Authorization: `Bearer ${accessToken}`
            };

            try {
                const myHeaders = new Headers();
                myHeaders.append('Authorization', headers.Authorization);

                const requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: formData,
                    redirect: 'follow'
                };

                fetch(
                    `https://api.latsuccess.com/api/lead-requests/${leadReqId}/upload`,
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result);
                        // Assume result.data contains the array for tableData
                        setTableData(result.data); // Update table data state with response
                    })
                    .catch((error) => console.error(error));
            } catch (error) {
                console.error('Error uploading file:', error);
            }
            e.target.value = null;
        }
    };

    const deleteTableRow = (index) => {
        const newData = [...tableData];
        newData.splice(index, 1);
        setTableData(newData);
    };

    const changePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const changeRowsPerPage = (event) => {
        setRowsPerPageCount(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    //--------------------------downlaoding csv--------------------------//

    const handleDownloadClick = async () => {
        const accessToken = localStorage.getItem('access_token');
        const headers = {
            Authorization: `Bearer ${accessToken}`
        };
        try {
            const response = await fetch('https://api.latsuccess.com/api/leads/download-sample', {
                method: 'GET', // Specify the method (GET, POST, etc.)
                headers: headers // Include the headers
            });
            const data = await response.text(); // Assuming the API returns CSV data as plain text

            // Create a Blob from the CSV string
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

            // Use the saveAs function to trigger a download
            saveAs(blob, 'sample.csv');
        } catch (error) {
            console.error('Error downloading the CSV:', error);
        }
    };

    //----------------------modal--------------------------//

    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (tableData.length > 0) {
            setModalIsOpen(true);
        }
    }, [tableData]);

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const handleGoBack = () => {
        navigate(-1); // Goes back one page in history
    };
    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <Button
                    variant="contained"
                    sx={{
                        width: 100,
                        color: 'white',
                        background: colors.primary[500],
                        fontSize: '15px',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            background: '#5A4532' // Dark brown color on hover
                        },
                        marginLeft: 'auto', // Align the button to the right
                        marginLeft: '80px'
                    }}
                    onClick={handleGoBack}
                >
                    Back
                </Button>
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>Orders </h1>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '20px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '10px',
                                        marginTop: '25px'
                                    }}
                                >
                                    <div></div>
                                    <Select
                                        sx={{ width: '150px' }}
                                        value={selectedCityFilter}
                                        onChange={handleCityFilterChange}
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        {uniqueCities.map((city) => (
                                            <MenuItem key={city} value={city}>
                                                {city}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: 200,
                                            color: 'white',
                                            background: colors.primary[500],
                                            fontSize: '12px',
                                            transition: 'background-color 0.3s',
                                            '&:hover': {
                                                background: '#5A4532' // Dark brown color on hover
                                            },
                                            marginLeft: 'auto' // Align the button to the right
                                        }}
                                        onClick={handleDownloadClick}
                                    >
                                        Download Sample CSV
                                    </Button>
                                </>
                            </Box>
                        </Box>

                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 600 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        border: '1px solid rgba(0, 0, 0, 0.1)',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            ?.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code}
                                                        sx={{
                                                            '&:nth-of-type(even)': {
                                                                backgroundColor:
                                                                    'rgba(0, 0, 0, 0.03)'
                                                            }
                                                        }}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                >
                                                                    {column.id === 'assign' ? (
                                                                        <>
                                                                            <Button
                                                                                variant="contained"
                                                                                sx={{
                                                                                    width: 100,
                                                                                    color: 'white',
                                                                                    background:
                                                                                        colors
                                                                                            .primary[500],
                                                                                    fontSize:
                                                                                        '15px',
                                                                                    transition:
                                                                                        'background-color 0.3s',
                                                                                    '&:hover': {
                                                                                        background:
                                                                                            '#5A4532' // Dark brown color on hover
                                                                                    },
                                                                                    marginLeft:
                                                                                        'auto' // Align the button to the right
                                                                                }}
                                                                                onClick={
                                                                                    handleButtonClick
                                                                                }
                                                                            >
                                                                                Import
                                                                            </Button>
                                                                            <input
                                                                                type="file"
                                                                                ref={fileInputRef}
                                                                                style={{
                                                                                    display: 'none'
                                                                                }}
                                                                                accept=".xlsx, .xls"
                                                                                onChange={
                                                                                    handleFileChange
                                                                                }
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>

                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={{
                                content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '70%',
                                    maxHeight: '80%',
                                    overflow: 'auto',
                                    zIndex: 1050 // Ensure this is higher than the table's z-index
                                },
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                    zIndex: 1040 // Ensure this is higher than the table's z-index
                                }
                            }}
                            contentLabel="Table Data Modal"
                        >
                            <Button onClick={closeModal} style={{ float: 'right' }}>
                                Close
                            </Button>
                            <div style={{ clear: 'both' }}></div>
                            {tableData.length > 0 && (
                                <Paper sx={{ overflow: 'hidden', marginTop: 2 }}>
                                    <TableContainer sx={{ maxHeight: 500 }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {Object.keys(tableData[0]).map((key) => (
                                                        <TableCell
                                                            style={{
                                                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                                                fontWeight: 'bold'
                                                            }}
                                                            key={key}
                                                        >
                                                            {key.replace(/_/g, ' ').toUpperCase()}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableData
                                                    .slice(
                                                        currentPage * rowsPerPageCount,
                                                        currentPage * rowsPerPageCount +
                                                            rowsPerPageCount
                                                    )
                                                    .map((row, index) => (
                                                        <TableRow key={index}>
                                                            {Object.keys(row).map((key, idx) => (
                                                                <TableCell key={idx}>
                                                                    {key === 'assigned'
                                                                        ? row[key]
                                                                            ? 'Yes'
                                                                            : 'No'
                                                                        : row[key]}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={tableData.length}
                                        rowsPerPage={rowsPerPageCount}
                                        page={currentPage}
                                        onPageChange={changePage}
                                        onRowsPerPageChange={changeRowsPerPage}
                                    />
                                </Paper>
                            )}
                        </Modal>
                    </Box>
                    <AssignLeads
                        open={isAssignOpen}
                        rowData={selectedRowData1}
                        onClose={() => setIsAssignOpen(false)}
                    />
                </div>
            </main>
        </>
    );
}

export default TableOrders;
